import { IComponentController } from '@wix/editor-elements-types';
import { StateRefsValues } from '@wix/thunderbolt-symbols';
import TPAMapActionToProps from '../../TPAGallery/viewer/TPAGallery.controller';

const mapStateToProps = ({ reducedMotion }: StateRefsValues) => ({
  reducedMotion,
});

const compController: IComponentController = {
  mapActionsToProps: TPAMapActionToProps as any,
  mapStateToProps,
};

export default compController;
